<template>
    <v-card
        @click="openUpdateDialogProfile"
        flat
        class="d-flex align-center pl-1 mr-1 my-1"
    >
        <v-dialog v-model="updateDialogProfile" persistent max-width="500px">
            <ContactSupplierForm
                title="Contact Information"
                :createForm="false"
                @closeDialog="closeUpdateDialogProfile"
                :contact="this.contactToUpdate"
                :originalContact="this.originalContact"
                :supplierId="this.contact.supplierId"
                @removeContact="removeContact"
                @replaceContact="replaceContact"
            />
        </v-dialog>
        <v-avatar color="grey lighten-4" size="36" class="me-2 my-1">
            <v-img
                v-if="contact.profilePicURL"
                :src="contact.profilePicURL"
                :alt="contact.name"
            />
            <span v-else class="text-h6">
                {{
                    contact.name
                        .split(' ')
                        .map((n, i, a) =>
                            i === 0 || i + 1 === a.length ? n[0] : null
                        )
                        .join('')
                        .toUpperCase()
                }}
            </span>
        </v-avatar>

        <div class="d-flex flex-column me-4">
            <span class="font-weight-bold text-singleline">
                {{ contact.name }}
            </span>
        </div>
    </v-card>
</template>

<script>
import ContactSupplierForm from '@/components/Suppliers/ContactSupplierForm'
export default {
    name: 'ContactSupplier',
    components: {
        ContactSupplierForm,
    },
    props: {
        contact: Object,
        projects: Array,
    },
    data() {
        return {
            updateDialogProfile: false,
            contactToUpdate: null,
            originalContact: null,
            idProject: null,
        }
    },
    methods: {
        openUpdateDialogProfile: function() {
            const contactToUpdate = this.contact
            this.contactToUpdate = JSON.parse(JSON.stringify(contactToUpdate))
            this.originalContact = JSON.parse(JSON.stringify(contactToUpdate))
            this.updateDialogProfile = true
        },

        closeUpdateDialogProfile: function() {
            this.updateDialogProfile = false
        },
        removeContact: function() {
            this.$emit('removeContact', this.contact.id)
            if (this.projects) {
                this.projects.forEach(element => {
                    if (element.reporter.id == this.contact.id) {
                        element.reporter = ''
                        this.idProject = element.id
                    }
                })
            }
        },
        replaceContact: function(id, contact) {
            this.$emit('replaceContact', id, contact)
        },
    },
}
</script>
